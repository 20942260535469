.navbar-dark .navbar-brand {
    color: rgb(255, 221, 127);
    background-color: rgb(143, 159, 60);
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: rgb(24, 61, 161);
  }

.navbar-dark .navbar-nav .nav-link {
    color: rgb(255, 221, 127);
    background-color: rgb(143, 159, 60);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(24, 61, 161);
  }

.bg-green {
    background-color: rgb(143, 159, 60);
  }

  .card {
    background-color: rgb(231, 201, 169);
  }